<template>
  <div>
    <!-- :style="{'background-image': `url(${ poster })`,}" -->
    <!-- :class="contentClass" -->
    <div class="content-card-div">
      <div
        class="close-div"
        v-if="displayClose || (content.progress && continueWatching)"
        @click="actDelete"
      >
        <img src="@/assets/icons/close_listing.svg" alt />
      </div>
      <div class="img-div" :style="`padding:${imagePadding}% 0`">
        <img
          class="content-img content-card"
          :src="defaultImage"
          :data-src="poster"
          alt="posters"
          @click="detailClick($event)"
          @load="
            (e) => {
              imageSource = e.target.src;
            }
          "
          :class="[
            content.progress && continueWatching
              ? 'progress-style'
              : 'hover-effect',
          ]"
          ref="imageElement"
        />
        <!-- <div class="premium-highlight" v-if="isPremium"><img src="@/assets/icons/premium_star.svg" alt="premium"></div> -->
      </div>
      <div
        class="content-img-card"
        :class="liveTagDisplay ? 'content-live' : ''"
      >
        <div>
          <img
            class="play-button"
            v-if="content.progress && continueWatching"
            @click="playContent()"
            src="@/assets/icons/play-btn.svg"
            alt="play"
            loading="lazy"
          />
        </div>
        <!-- <div
          class="new-tag"
          v-if="displayNewTag && (content.contenttype ==='MOVIE' || content.contenttype === 'SERIES')"
        >New</div>-->

        <div
          class="live-tag tag-position"
          v-if="liveTagDisplay && content.contenttype !== 'EVENT'"
        >
          LIVE
        </div>
      </div>

      <div v-if=" isPremium " class="new-tag content-movie-show premium-tag">
        <img :src="premiumIcon" alt="premium"><span>premium</span>
      </div>
      <div
        class="new-tag"
        :class="displayNewTag ? 'content-movie-show' : ''"
        v-else-if="
          displayNewTag &&
          (content.contenttype === 'MOVIE' ||
            content.contenttype === 'SERIES' ||
            content.contenttype === 'TVSHOW')
        "
      >
        New
      </div>
      <div class="card-progress" v-if="content.progress && continueWatching">
        <div
          class="progress-percent"
          :id="`progress-${content.contentid}`"
          :style="{ width: content.progress + '%' }"
        ></div>
        <!-- <div
          class="progress-percent"
          :id="`progress-${content.contentid}`"
          :style="{ width: contentProgress + '%' }"
        ></div>-->
      </div>
    </div>
    <!-- v-if="content.contentid === arrowName" -->
    <!-- <div v-if="arrowName[content.contentid]" class="arrow-div"> -->
    <!-- <div class="arrow"></div> -->
    <!-- </div> -->
    <div class="info" v-if="showInfo">
      <div class="card-left">
        <div class="card-title">
          {{ content.episodenum ? `E${content.episodenum} : ` : "" }}{{ title }}
        </div>
        <div class="featured-tags" v-if="!continueWatching">
          <div v-for="(tags, key) in featuredTags" :key="key">
            <div v-if="tags">
              <p>{{ tags }}</p>
            </div>

            <div
              v-else-if="isDisplayLive || isDisplayEnded"
              class="live-tag"
              v-text="isDisplayLive ? 'LIVE' : isDisplayEnded ? 'ENDED' : ''"
            ></div>
          </div>
        </div>
        <div v-else class="featured-spacing"> </div>
        <!-- <div class="featured-dec">
          <p>{{ content.description }}</p>
        </div> -->
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import purchaseMixins from "@/mixins/purchaseMixins";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import FirebasePreload from "@/mixins/FirebasePreload";
import {getStartDate} from '@/mixins/dateSupport.js';
// import FirebaseActions from "@/mixins/FirebaseActions";

export default {
  props: {
    showContentDetailEvent: {
      type: Function,
    },
    removeContent: {
      type: Function,
    },
    content: {
      type: Object,
    },

    contentId: {
      type: String,
    },
    orientation: {
      type: String,
    },
    showInfo: {
      type: Boolean,
    },
    displayClose: {
      type: Boolean,
    },
    fromPage: {
      type: String,
    },
    detailsActive: {
      type: Boolean,
    },
    continueWatching: {
      type: Boolean,
    },
    positionButtons: {
      type: Function,
      default: null,
    },
    lazyObserver: {
      type: Function,
      default(el) {
        el.onerror = () => {
          el.src = this.defaultImage;
        };
        el.src = el.dataset.src;
      },
    },
    defaultImage: {
      type: String,
      default() {
        return this.orientation === "LANDSCAPE"
          ? require("@/assets/icons/placeholder-landscape.png")
          : require("@/assets/icons/placeholder-potrait.png");
      },
    },
  },
  data() {
    return {
      progress: null,
      showCompleted: false,
      showWatchlistBtn: false,
      showDownArrow: false,
      callTimer: null,
      featuredTags: [],
      displayNewTag: false,
      title: null,
      arrowName: [],
      isWatch: false,
      isDisplayLive: false,
      isDisplayEnded: false,
      imageSource: this.defaultImage,
      image_observer: null,
      timerId:null,
      premiumIcon: require('@/assets/icons/star.svg')
      // displayArrow: false
    };
  },
  watch: {
    arrowName() {},
  },
  computed: {
    ...mapGetters(["subscriberId", "ticketResponse", "appConfig"]),
    isPremium(){
      return ( this.content?.pricemodel==='PREMIUM' &&
          ( Array.isArray( this.ticketResponse )? 
            this.ticketResponse.every( ticket => ticket.contentid !== this.content.contentid ) : true 
          ) 
        );
    },
    purchase(){
      return ( this.appConfig?.featureEnabled?.purchase && 
                     this.p_priceAvailable( this.content )
                    );
    },
    subscription(){
      return ( this.appConfig?.featureEnabled?.subscription
              && ( this.content?.pricemodel !== 'PREMIUM' )  
            );
    },
    poster() {
      if (this.content.posters && this.content.posters.length) {
        if (this.orientation === "LANDSCAPE") {
          let landscapePosters = this.content.posters.filter(
            (pos) => pos.orientation === "LANDSCAPE"
          );
          // if (landscapePosters.length == 0)
          //   landscapePosters = this.content.posters.filter(
          //     (pos) => pos.orientation === "PORTRAIT"
          //   );
          if (landscapePosters.length) {
            let posterIndex = landscapePosters.findIndex(
              (pos) => pos.quality === "SD"
            );
            if (posterIndex > -1) {
              return landscapePosters[posterIndex].url;
            } else {
              return landscapePosters[0].url;
            }
          } else return null;
        } else {
          let portraitPosters = this.content.posters.filter(
            (pos) => pos.orientation === "PORTRAIT"
          );
          // if (portraitPosters.length == 0)
          //   portraitPosters = this.content.posters.filter(
          //     (pos) => pos.orientation === "LANDSCAPE"
          //   );
          if (portraitPosters.length) {
            let posterIndex = portraitPosters.findIndex(
              (pos) => pos.quality === "SD"
            );
            if (posterIndex > -1) {
              return portraitPosters[posterIndex].url;
            } else {
              return portraitPosters[0].url;
            }
          } else return null;
        }
      } else {
        return this.content.poster;
      }
    },
    imagePadding() {
      return this.orientation === "LANDSCAPE" ? "28.125" : "75";
    },
    contentClass() {
      if (this.orientation === "LANDSCAPE") {
        return "content-card-land";
      } else if (this.orientation === "PORTRAIT") {
        return "content-card-port";
      }
      else return '';
    },
    liveTagDisplay() {
      return (
        this.content.contenttype === "LIVE" || this.isDisplayLive
      );
    },
  },
  methods: {
    ...mapActions(["actGetPlans", "actGetContent"]),

    eventCheck(){
        if (
            this.content.premieringstatus !== "ANNOUNCED" &&
            this.content.premieringstatus !== "PREBOOK"
          ) {

              if (this.content.todate && this.checkEventEnded(this.content.todate)) {
                  this.isDisplayEnded = true;
                  this.isDisplayLive = false;
              } else{
                this.isDisplayEnded = false;
                this.isDisplayLive = true;
              }  
          } 
        const prestatus=this.content.premieringstatus;
        if(!prestatus)return;
        if(prestatus.includes('ANNOUNCED')){
          this.startdate='Coming soon';
          this.featuredTags=[this.startdate];
        }
        else if(prestatus.includes('PREBOOK')){
            this.startdate=getStartDate(this.content.fromdate);
            if(this.startdate)this.featuredTags=[this.startdate];
            if(this.startdate?.includes('in')){
                let fromdate=this.content.fromdate;
                this.timerId=setInterval(()=>{
                    this.startdate=getStartDate(this.content.fromdate);
                    this.featuredTags=[this.startdate];
                    if(!this.startdate){
                        clearInterval(this.timerId);
                        this.featuredTags=[];
                        this.timerId=null;
                    }
                },1000)
            }
    
        }
    },
    featuredTagsFun() {
      // let params = [];
      let _this = this;
      this.featuredTags = [];
      switch (this.content.contenttype) {
        case "EVENT":
          this.eventCheck();
          // return params;
          break;
        case "LIVE":
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.getLanguage(this.content.language));
          // return params;
          break;
        case "MOVIE":
          if (this.content.progress && this.continueWatching) {
            this.featuredTags.push(this.content.contenttype);
          }

          let duration = this.getDuration(this.content.duration);
          this.featuredTags.push(this.content.category);

          if (!this.content.progress && !this.continueWatching) {
            this.featuredTags.push(this.content.year);
          }

          this.featuredTags.push(duration);
          // return params;
          break;
        case "SERIES":
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.content.year);
          if (this.content.episodecount) {
            this.featuredTags.push(this.content.episodecount + " Episodes");
          }
          // return params;
          break;
        case "TVSHOW":
          if (this.content.progress && this.continueWatching) {
            if (this.content.seriesid) {
              this.featuredTags.push("SERIES");
            } else {
              this.featuredTags.push(this.content.contenttype);
            }
          }

          let durationShows = this.getDuration(this.content.duration);
          // this.featuredTags.push(this.content.contenttype);
          this.featuredTags.push(this.content.category);
          // if(this.content.language)this.featuredTags.push(this.getLanguage(this.content.language));
          if (!this.content.progress && !this.continueWatching) {
            this.featuredTags.push(this.content.year);
          }
          if (durationShows) this.featuredTags.push(durationShows);

          // return params;
          break;
      }
    },

    detailClick(event) {
      if (
        this.content.seriesid &&
        this.content.episodenum &&
        this.content.contentid !== this.content.seriesid
      ) {
        this.isWatch = this.actPurchaseStatus(this.content).isDisplayWatch;
        return this.playContent();
      }
      this.routeToCardView(this.content.contentid);
      //EventBus.$emit("openDetailCard", {detailCard:this.content});
    },
    playContent() {
      if (this.isWatch) {
       
        if (this.content.contenttype === "SERIES") {
          this.content.videoType = "S";
        } else if (this.content.contenttype === "MOVIE") {
          this.content.videoType = "M";
        } else if (this.content.contenttype === "LIVE") {
          this.content.videoType = "L";
        } else if (this.content.contenttype === "EVENT") {
          this.content.videoType = "E";
        }
        EventBus.$emit("loadPlayer", this.content);
      } else {

        if (!this.subscriberId) {
          return EventBus.$emit("LoginPopup");
        }
        let data = this.content.seriesid
          ? this.content.seriesid
          : this.content.contentid;
        this.actGetContent(data)
          .then((response) => {
            if (!response.error) {
              this.updateWatch(response);
              if (this.isWatch) return this.playContent();
             
              let data = {};
              if (this.purchase && this.subscription) {
                data = {
                  intermediate: true,
                  content: response,
                };
              } else if (this.subscription) {
                data = {
                  subscription: true,
                  menuClicked: false,
                };
                this.backData = "subscription";
              } else if (this.purchase) {
                data = {
                  purchase: true,
                  content: response,
                };
                this.backData = "purchase";
              }
              //if both flags false the isWatch is true
              if (this.purchase || this.subscription)
                EventBus.$emit("paymentInitPopup", data);
            }
          })
          .catch((error) => {
            console.log("error ", error);
            if (error.status === 401) {
              this.commonAutoLogin(this.plansSetValue);
              return;
            }
          });
      }
    },
    removeActiveClass() {
      // this.displayArrow = false;
      this.arrowName = [];
      let current = document.getElementsByClassName("active-content");
      if (current.length) {
        current[0].classList.remove("active-content");
      }
    },
    checkNewTag() {
      if (
        this.content &&
        this.content.tags &&
        this.content.tags.length >= 1 &&
        typeof this.content.tags === "object"
      ) {
       
        let tagTemp = this.content.tags.map((v) => v.toLowerCase());
        
        if (tagTemp.includes("new")) {
          this.displayNewTag = true;
        } else {
          this.displayNewTag = false;
        }
      }
    },
    updateWatch(content = this.content) {
      this.isWatch = this.actPurchaseStatus(content).isDisplayWatch;
    },
    actDelete() {
      let param = {};

      if (!this.fromPage) {
        if (this.content.progress && this.continueWatching) {
          param.frompage = "continuewatching";
        }
      } else {
        param.frompage = this.fromPage;
      }

      // if (this.content.seriesid) {
      //   param.seriesid = this.content.seriesid;
      // } else {
      //   param.contentid = this.content.contentid;
      // }

      param.contentid = this.content.contentid;

      if (this.content.seriesid || this.content.contenttype === "SERIES") {
        param.seriesid = this.content.seriesid;
        param.nodetype = "series";
      } else {
        param.nodetype = "content";
      }


      this.deleteFirebase(param).then((_) => {
        EventBus.$emit("deleteContent", param);
      });

      // setTimeout(() => {
      //   EventBus.$emit("deleteContent", true);
      // }, 1000);
    },
    pushHeight() {
      this.positionButtons(this.$refs.imageElement.height);
    },
  },
  created() {
    this.title = this.content.title
      ? this.content.title
      : this.content.contenttitle;

    EventBus.$on("closeAllArrow", this.removeActiveClass);
    EventBus.$on("purchaseDetailsUpdated", this.updateWatch);
  },
  mounted() {
    this.featuredTagsFun();
    this.checkNewTag();
    if (this.content.progress && this.continueWatching) {
      this.updateWatch();
    }
    if (this.$refs.imageElement) this.lazyObserver(this.$refs.imageElement);
    if (this.positionButtons) {
      this.$refs.imageElement.addEventListener("load", this.pushHeight);
      this.image_observer = new ResizeObserver(this.pushHeight);
      this.image_observer.observe(this.$refs.imageElement);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
    EventBus.$off("closeAllArrow", this.removeActiveClass);
    EventBus.$off("purchaseDetailsUpdated", this.updateWatch);
    if (this.positionButtons) {
      this.$refs.imageElement.removeEventListener("load", this.pushHeight);
      this.image_observer && this.image_observer.disconnect();
    }
  },
  mixins: [appMixins, purchaseMixins, FirebasePreload],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.arrow-div {
  display: flex;
  justify-content: center;
  margin-top: -5px;
  .arrow {
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid $theme-primary;
  }
}
.close-div {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 2%;
  display: flex;
  top: 0px;
  justify-content: flex-end;
  cursor: pointer;
  img {
    &:hover {
      transform: scale(1.1);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}
.feature-margin {
  margin: 4%;
}
.content-card-div {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.content-card-div:hover {
  .play-button {
    width: 110%;
  }
}
.img-div {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding: 28.125% 0;
  position: relative;
  cursor: pointer;
  .content-img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
  }
  .premium-highlight{
    position:absolute;
    bottom:5px;
    left:5px;
    width:8%;
    height:max-content;
    img[alt='premium']{
      display:block;
      width:100%;
    }
  }
}
.tag-position {
  position: absolute;
}
.content-card-div .live-tag {
  // position: absolute;
  top: 7px;
  right: 8px;
}
.content-card {
  box-shadow: 0px 0px 10px $clr-color-dark-1;
  border-radius: 6px;
  overflow: hidden;
  background-size: cover;
}
.content-img-card {
  position: absolute;
  width: 20%;
  div {
    width: 100%;
    img {
      width: 100%;
    }
  }
  // top: 10px;
}
.content-live {
  // right: 10px;
  right: 0;
  top: 0;
}
.content-movie-show {
  // left: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
}
// .content-card-land {
//   min-height: 200px;
//   max-height: 200px;
// }
//.content-card-port {
// height: 400px;
// min-height: 475px;
// max-height: 475px;
// min-height: 384px;
// max-height: 384px;
// min-height: 502px;
// max-height: 502px;
//min-height: 540px;
// max-height: 540px;
//}
.play-button {
  cursor: pointer;
}
.card-progress {
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: $clr-dark-hlt-7;
  left: 0;
  bottom: 10px;
  margin-top: 3px;
  .progress-percent {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $clr-color-red-1;
  }
}
.hover-effect {
  width: 100%;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background: linear-gradient(0deg, $clr-dark-hlt-2, transparent);
    transition: all 0.3s ease;
    border: 2px solid $theme-primary;
    border-radius: 6px;
  }
}
.progress-style {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: linear-gradient(0deg, $clr-dark-hlt-2, transparent);
    transition: all 0.3s ease;
    border: 2px solid $theme-primary;
    border-radius: 6px;
  }
}
.live-tag {
  text-align: center;
  background: $clr-color-red 0% 0% no-repeat padding-box;
  border-radius: 2px;
  padding: 3px 10px;
  font-size: 0.625rem;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  color: $font-clr-white;
}
.new-tag {
  // background: $clr-color-green 0% 0% no-repeat padding-box;
  background: $tag-primary;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: 1rem;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  color: $font-clr-white;
}
.premium-tag{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  text-transform: uppercase;
  img{
    display: block;
    height: 1rem;
  }
  span{
    display: inline-block;
    vertical-align: bottom;
    font-size: 1rem;
    height: 1.5rem;
    line-height: 1.6rem;
  }
}
.info {
  margin-top: 5%;
}
.card-left {
  float: left;
  width: 100%;
  .card-title {
    // font-size: 1.7rem;
    font-size: 1rem;
    color: $font-clr-white;
    text-transform: capitalize;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .featured-tags {
    display: flex;
    flex-wrap: wrap;
    margin: 0.625rem 0px;
    // margin: 10px 0px;
    p {
      float: left;
      opacity: 0.76;
      color: $tag-primary;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      line-height: 15px;
      margin-right: 10px;
      padding: 3px 0px;
      // margin-top: -1%;
    }
    min-height: 21px;
    &::before {
      content: "";
      margin: 0.1px;
    }
  }
  .featured-spacing{
    margin-bottom:0.625rem;
  }
  .featured-dec {
    margin: 0 0 1em 0;
    // overflow: hidden;
    width: 100%;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: calc(1.5rem * 2);
    overflow: hidden;
    // height: 35px;
    p {
      float: left;
      opacity: 0.76;
      color: $font-clr-white-1;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
.active-content {
  border: 2px solid $theme-primary;
  border-radius: 12px;
  &::after {
    content: "";
    border-color: $theme-primary transparent transparent transparent;
    border-style: solid;
    border-width: 13px;
    position: absolute;
    bottom: -11%;
    left: 45%;
  }
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 576px) {
@include breakpoint(max600) {
  // .content-card-land {
  //   min-height: 160px;
  //   max-height: 160px;
  // }
  //.content-card-port {
  //min-height: 420px !important;
  //max-height: 420px !important;
  //}
  .featured-dec {
    height: calc(1.5rem * 3);
  }
}

@include breakpoint(min980max1280) {
  // .content-card-land {
  //   min-height: 146px !important;
  //   max-height: 146px !important;
  // }
  //.content-card-port {
  // min-height: 360px !important;
  // max-height: 360px !important;
  //}
}
@include breakpoint(min768max980) {
  // .content-card-land {
  //   min-height: 175px !important;
  //   max-height: 175px !important;
  // }
}
</style>