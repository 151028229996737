var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-card-div"},[(_vm.displayClose || (_vm.content.progress && _vm.continueWatching))?_c('div',{staticClass:"close-div",on:{"click":_vm.actDelete}},[_c('img',{attrs:{"src":require("@/assets/icons/close_listing.svg"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"img-div",style:(("padding:" + _vm.imagePadding + "% 0"))},[_c('img',{ref:"imageElement",staticClass:"content-img content-card",class:[
          _vm.content.progress && _vm.continueWatching
            ? 'progress-style'
            : 'hover-effect' ],attrs:{"src":_vm.defaultImage,"data-src":_vm.poster,"alt":"posters"},on:{"click":function($event){return _vm.detailClick($event)},"load":function (e) {
            _vm.imageSource = e.target.src;
          }}})]),_c('div',{staticClass:"content-img-card",class:_vm.liveTagDisplay ? 'content-live' : ''},[_c('div',[(_vm.content.progress && _vm.continueWatching)?_c('img',{staticClass:"play-button",attrs:{"src":require("@/assets/icons/play-btn.svg"),"alt":"play","loading":"lazy"},on:{"click":function($event){return _vm.playContent()}}}):_vm._e()]),(_vm.liveTagDisplay && _vm.content.contenttype !== 'EVENT')?_c('div',{staticClass:"live-tag tag-position"},[_vm._v(" LIVE ")]):_vm._e()]),( _vm.isPremium )?_c('div',{staticClass:"new-tag content-movie-show premium-tag"},[_c('img',{attrs:{"src":_vm.premiumIcon,"alt":"premium"}}),_c('span',[_vm._v("premium")])]):(
        _vm.displayNewTag &&
        (_vm.content.contenttype === 'MOVIE' ||
          _vm.content.contenttype === 'SERIES' ||
          _vm.content.contenttype === 'TVSHOW')
      )?_c('div',{staticClass:"new-tag",class:_vm.displayNewTag ? 'content-movie-show' : ''},[_vm._v(" New ")]):_vm._e(),(_vm.content.progress && _vm.continueWatching)?_c('div',{staticClass:"card-progress"},[_c('div',{staticClass:"progress-percent",style:({ width: _vm.content.progress + '%' }),attrs:{"id":("progress-" + (_vm.content.contentid))}})]):_vm._e()]),(_vm.showInfo)?_c('div',{staticClass:"info"},[_c('div',{staticClass:"card-left"},[_c('div',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.content.episodenum ? ("E" + (_vm.content.episodenum) + " : ") : "")+_vm._s(_vm.title)+" ")]),(!_vm.continueWatching)?_c('div',{staticClass:"featured-tags"},_vm._l((_vm.featuredTags),function(tags,key){return _c('div',{key:key},[(tags)?_c('div',[_c('p',[_vm._v(_vm._s(tags))])]):(_vm.isDisplayLive || _vm.isDisplayEnded)?_c('div',{staticClass:"live-tag",domProps:{"textContent":_vm._s(_vm.isDisplayLive ? 'LIVE' : _vm.isDisplayEnded ? 'ENDED' : '')}}):_vm._e()])}),0):_c('div',{staticClass:"featured-spacing"})]),_c('div',{staticClass:"clearfix"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }