<template>
  <div>
    <div class="content-card-div">
      <div class="img-div">
        <img
          class="content-img content-card"
          :src="poster ? poster : defaultLandsacapePoster"
          alt="posters"
        />
        <div
          class="play-btn"
          v-show="content.premieringstatus !== 'PREBOOK' && !isDisplayEnded"
        >
          <img
            class="play-button"
            @click="playContent()"
            src="@/assets/icons/play-btn.svg"
            alt="play"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="info">
      <div class="card-left">
        <div class="card-title">{{ content.contenttitle }}</div>
        <!-- <div class="card-type">{{ content.contenttype}}</div> -->
        <div class="featured-tags" v-if="!isDisplayEnded && !isDisplayLive">
          <p v-for="(tags, key) in featuredTags" :key="key">{{ tags }}</p>
          <span class="quality-btn">{{ content.quality }}</span>
        </div>
        <div
          v-else
          class="live-tag-div"
          v-text="isDisplayLive ? 'LIVE' : isDisplayEnded ? 'ENDED' : ''"
        ></div>
        <!-- <div class="featured-dec">
          <p>{{ content.contentdescription }}</p>
        </div> -->
        <div v-if="expiry" class="main-dot">
          <!-- <div class="dot"> -->
          <div class="dot"></div>
          <div class="expiry">{{ expiry }}</div>
          <!-- </div> -->
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import {getStartDate} from '@/mixins/dateSupport.js';

export default {
  props: {
    showContentDetailEvent: {
      type: Function,
    },
    removeContent: {
      type: Function,
    },
    content: {
      type: Object,
    },
    orientation: {
      type: String,
    },
  },
  data() {
    return {
      progress: null,
      showCompleted: false,
      showWatchlistBtn: false,
      showDownArrow: false,
      expiry: "",
      defaultLandsacapePoster: require("@/assets/icons/placeholder-landscape.png"),
      featuredTags: [],
      isDisplayLive: false,
      isDisplayEnded: false,
    };
  },
  computed: {
    ...mapGetters(["subscriberid"]),
    poster() {
      if (this.content.posters && this.content.posters.length) {
        let landscapePosters = this.content.posters.filter(
          (pos) => pos.orientation === "LANDSCAPE"
        );
        // if (landscapePosters.length == 0)
        //   landscapePosters = this.content.posters.filter(
        //     pos => pos.orientation === "PORTRAIT"
        //   );
        if (landscapePosters.length) {
          let posterIndex = landscapePosters.findIndex(
            (pos) => pos.quality === "SD"
          );
          if (posterIndex > -1) {
            return landscapePosters[posterIndex].url;
          } else {
            // return landscapePosters[0].url;
            return '';
          }
        }
        else return '';
      } else {
        // return this.content.poster;
        return '';
      }
    },
    contentClass() {
      if (this.orientation === "LANDSCAPE") {
        return "content-card-land";
      } else if (this.orientation === "PORTRAIT") {
        return "content-card-port";
      }
      else return '';
    },
  },
  watch: {
    content(val) {
      this.content = val;
    },
  },
  methods: {
    ...mapActions(["actGetContent"]),
    // featuredTagsFun() {
    //   // let params = [];
    //   let _this = this;
    //   this.featuredTags = [];
    // this.featuredTags.push(this.content.category);
    // this.featuredTags.push(this.content.year);
    // if (this.content.duration > 0) {
    //   let duration = this.getDuration(this.content.duration);

    //   this.featuredTags.push(duration);
    // }
    // },
    featuredTagsFun() {
      this.timerDate = null;
      let _this = this;
      this.featuredTags = [];
      switch (this.content.contenttype) {
        case "EVENT":
          let startdate;
          if (this.content.fromdate) {
            startdate = getStartDate(this.content.fromdate);
            // console.log("start date ", startdate);
          } else {
            startdate = null;
          }
          // console.log("start");
          if (!startdate) {
            startdate = false;
            this.displayLive = true;
            EventBus.$emit("checkWatchButton", true);
          } else {
            this.displayLive = false;
            let callFun = startdate.search("in");
            if (callFun) {
              let time = startdate.split(" ");
              if (time[2] !== "00:00:00") {
                setTimeout(function () {
                  _this.featuredTagsFun();
                }, 1000);
              }
            }
          }

          if (
            this.content.contenttype === "EVENT" &&
            this.content.premieringstatus !== "ANNOUNCED" &&
            this.content.premieringstatus !== "PREBOOK"
          ) {
            if (this.content.todate) {
              if (this.checkEventEnded(this.content.todate)) {
                this.isDisplayEnded = true;
                this.isDisplayLive = false;
              } else {
                this.isDisplayEnded = false;
                this.isDisplayLive = true;
              }
              // console.log(this.content, this.isDisplayEnded);
            } else {
              this.isDisplayEnded = false;
              this.isDisplayLive = true;
            }
          }

          // this.featuredTags.push(startdate);

          if (this.content.premieringstatus === "ANNOUNCED") {
            startdate = "Coming soon";
          }
          this.featuredTags.push(startdate);

          this.timerDate = startdate;
          // return params;
          break;
        case "LIVE":
          this.featuredTags.push(this.content.contenttype);
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.getLanguage(this.content.language));
          this.featuredTags.push(this.content.year);

          // return params;
          break;
        case "MOVIE":
          // this.content.duration
          let duration = this.getDuration(this.content.duration);
          this.featuredTags.push(this.content.contenttype);
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.getLanguage(this.content.language));
          this.featuredTags.push(duration);
          this.featuredTags.push(this.content.year);
          // return params;
          break;
        case "SERIES":
          this.featuredTags.push(this.content.contenttype);
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.getLanguage(this.content.language));
          this.featuredTags.push(this.content.year);
          if (this.content.episodecount) {
            this.featuredTags.push(this.content.episodecount + " Episodes");
          }
          // return params;
          break;
        case "TVSHOW":
          let durationShows = this.getDuration(this.content.duration);
          this.featuredTags.push(this.content.contenttype);
          this.featuredTags.push(this.content.category);
          this.featuredTags.push(this.getLanguage(this.content.language));
          this.featuredTags.push(durationShows);
          this.featuredTags.push(this.content.year);
          // return params;
          break;
      }
    },
    getDuration(duration) {
      let hours;
      let minutes = Math.floor(duration / 60);
      if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
      }
      let seconds = duration % 60;

      let time;

      if (hours) {
        time = hours + "h " + minutes + "m " + seconds + "s";
      } else if (minutes) {
        time = minutes + "m " + seconds + "s";
      } else {
        time = seconds + "s";
      }
      return time;
    },
    playContent() {
      console.log("fromplay..", this.content.contenttype);
      if (this.content.contenttype === "SERIES") {
        this.content.videoType = "S";
      } else if (this.content.contenttype === "MOVIE") {
        this.content.videoType = "M";
      } else if (this.content.contenttype === "LIVE") {
        this.content.videoType = "L";
      } else if (this.content.contenttype === "EVENT") {
        this.content.videoType = "E";
      }
      let data = this.content.seriesid
        ? this.content.seriesid
        : this.content.contentid;

      this.actGetContent(data)
        .then((response) => {
          if (!response.error) {
            response.videoType = this.content.videoType;
            EventBus.$emit("loadPlayer", response);
          } else throw new Error();
        })
        .catch(() => {
          EventBus.$emit("loadPlayer", this.content);
        });
    },
  },
  created() {
    this.featuredTagsFun();
    if (this.content && this.content.expiry) {
      this.expiry = this.expiryDetails(this.content.expiry);
    }
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
.live-tag-div {
  // width: 10%;
  width: max-content;
  margin-bottom: 3%;
  text-align: center;
  background: $clr-color-red 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 0.9rem;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  color: $font-clr-white;
}
.play-btn {
  position: absolute;
  .play-button {
    cursor: pointer;
  }
}
.card-type {
  color: $clr-color-green;
}
.content-card {
  width: 254px;
  border-radius: 5px;
}
.content-card-div {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.img-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-img{
    border:2px solid transparent;
  }
}
.play-btn {
  display: none;
}
.img-div:hover {
  .content-img {
    border: 2px solid $theme-primary;
    cursor: pointer;
  }

  .play-btn {
    display: inline;
    position: absolute;
    .play-button {
      // opacity: 0;
      cursor: pointer;
    }
  }
}

.content-card-land {
  height: 200px;
}
.content-card-port {
  height: 400px;
}
.hover-effect {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  &:hover {
    background: linear-gradient(0deg, $clr-dark-hlt-2, transparent);
    transition: all 0.3s ease;
    border: 2px solid $theme-primary;
    border-radius: 12px;
  }
}
.live-tag {
  float: right;
  width: 12%;
  margin-top: 10px;
  margin-right: 10px;
  text-align: center;
  background: $clr-color-red 0% 0% no-repeat padding-box;
  border-radius: 2px;
  font-size: 1rem;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  color: $font-clr-white;
}
.info {
  margin-top: 5%;
}
.card-left {
  float: left;
  width: 100%;
  .card-title {
    font-size: 1rem;
    color: $font-clr-white;
    text-transform: capitalize;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .featured-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    .quality-btn {
      background-color: $theme-primary;
      color: $clr-white;
      padding: 2px 6px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;
    }
    p {
      float: left;
      opacity: 0.76;
      color: $tag-primary;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      // line-height: 20px;
      margin-right: 10px;
    }
  }
  .featured-dec {
    margin: 0 0 5px 0;
    overflow: hidden;
    width: 100%;
    height: 45px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    p {
      float: left;
      opacity: 0.76;
      color: $font-clr-white-1;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      // line-height: 15px;
    }
  }
  .featured-exp {
    color: $theme-primary;
  }
  .main-dot {
    display: flex;
    // justify-content: center;
    cursor: pointer;
  }
  .expiry {
    color: $theme-primary;
    font-size: 1rem;
    text-align: left;
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: $theme-primary;
    border-radius: 50%;
    display: inline-block;
    margin: 5px 5% 0% 0%;
  }
}

/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 576px) {
@include breakpoint(max600) {
  .content-card-port {
    height: 160px;
    .hover-effect {
      background: transparent;
    }
  }
  .card-title {
    font-size: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 576px) and (max-width: 768px) {
@include breakpoint(min600max768) {
  .content-card-port {
    height: 300px;
    .hover-effect {
      background: transparent;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) and (max-width: 992px) {
@include breakpoint(min768max980) {
  .content-card-port {
    height: 300px;
    .hover-effect {
      background: transparent;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
@include breakpoint(min1200) {
  .content-card-port {
    height: 350px;
  }
}
// 4k
// @media screen and (min-width: 1900px) {
@include breakpoint(desktopLarge) {
  .content-card-port {
    height: 600px;
  }
}
</style>